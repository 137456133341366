<template>
  <div class="charts">
    <div class="main">
      <div class="header">
        <h2 class="main-title">托育机构质量评估态势情况</h2>
        <span class="time">{{time}}</span>
      </div>
      <div class="content">
        <div class="left">

          <p class="part-title">托育机构自评趋势分析</p>
          <div id="l1">
            <div class="top">
              <el-date-picker class="picker" v-model="datetime" size="mini" type="daterange" range-separator="-"
                start-placeholder="开始时间" end-placeholder="结束时间" @change="initLeft1">
              </el-date-picker>
              <el-radio-group size="mini" v-model="r1tab" style="margin-bottom: 30px;" @change="initLeft1">
                <el-radio-button label="1">天</el-radio-button>
                <el-radio-button label="2">周</el-radio-button>
                <el-radio-button label="3">月</el-radio-button>
              </el-radio-group>
            </div>
            <div id="chart1" class="chart"></div>
          </div>
          <div class="ul" v-if="rightData1">
            <p class="part-title">质量评估检查内容概况</p>
            <div id="r1">
              <div class="l">
                <img src="@/assets/charts/i2.png" alt="">
                <div class="text">
                  <p style="color:#000;font-size:18px;font-weight:bold;">{{rightData1.content_summary.average_total_time
                    || '00:00:00'}}</p>
                  <p>机构评估平均用时</p>
                  <p style="margin-top:4px;color:#0083F5;font-size:20px;font-weight:bold;">
                    {{rightData1.content_summary.average_score}}<span style="font-size: 14px">分</span></p>
                  <p>机构评估平均得分</p>
                </div>
              </div>
              <div class="r">
                <img src="@/assets/charts/i1.png" alt="">
                <div class="text">
                  <p style="color:#000;font-size:18px;font-weight:bold;">
                    {{rightData1.content_summary.average_examine_total_time || '00:00:00'}}</p>
                  <p>督导评审平均用时</p>
                  <p style="margin-top:4px;color:#0083F5;font-size:20px;font-weight:bold;">
                    {{rightData1.content_summary.average_examine_score}}<span style="font-size: 14px">分</span></p>
                  <p>督导评审平均得分</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <p class="part-title">托育机构评估区域分析</p> -->
          <!-- <div id="l2"> -->
          <!-- <div id="chart2" class="chart"></div> -->
          <!-- <div class="input">
            <el-select v-model="city" clearable placeholder="请选择">
              <el-option v-for="item in citys" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div> -->
          <!-- <div class="list" v-if="leftData2">
            <p class="title"><span>省份</span><span>城市</span><span>机构数量</span><span>占比</span></p>
            <p v-for="item of leftData2.province_list" :key="item.code">
              <span>{{item.code}}</span>
              <span>{{item.score*1}}</span>
              <span>{{item.total*1}}</span>
              <span>{{item.inspect_ratio}}</span>
            </p>
          </div> -->
          <!-- </div> -->
          <p class="part-title">托育机构自评实时动态</p>
          <div id="l3" ref="l3">
            <div class="real-time" ref="realTime" v-if="leftTableData.length">
              <div class="item" v-for="(item,index) of leftTableData" :key="index">
                <p>
                  <span>{{item.organization_name}}</span>
                  <span style="color:#0079FE" v-if="item.results == 4">超过国家标准</span>
                  <span style="color:#1ABE6B" v-if="item.results == 3">符合国家标准</span>
                  <span style="color:#FFBA01" v-if="item.results == 2">接近国家标准</span>
                  <span style="color:#F04134" v-if="item.results == 1">需要重大改进</span>
                </p>
                <p v-if="item"><span>{{item.province_city_area}}</span><span>{{item.submit_date.slice(0,16)}}</span></p>
              </div>
              <p style="color:#666;line-height: 50px;font-size: 14px;text-align:center;">{{finished?'没有更多':'加载更多'}}</p>
            </div>
            <div style="line-height: 300px" v-else>暂无数据</div>
          </div>
        </div>
        <div class="center">
          <div class="number">
            <div class="item">
              <p>{{centerData ? centerData.top_data.org_count_all : '0'}}</p>
              <p>托育机构数量</p>
            </div>
            <div class="item">
              <p>{{centerData ? centerData.top_data.inspect_count_all : '0'}}</p>
              <p>已自评托育机构数量</p>
            </div>
            <div class="item">
              <p>{{centerData ? centerData.top_data.inspect_ratio_all : '0%'}}</p>
              <p>自评完成率</p>
            </div>
          </div>
          <div class="main-chart">
            <div class="pie">
              <p class="part-title">今日托育机构自评情况</p>
              <div id="c1">
                <div class="chart-wrap" style="margin: 0;">
                  <div id="chart3"></div>
                  <div class="ratio_text" v-if="centerData"
                    style="width:120px;height:120px;top: -14px;left:0;transform:translate(0,0);display:flex;align-items: center; color: #0083F5">
                    <h2 style="font-size: 30px;">{{centerData.today_data.today_count || 0}}</h2>
                  </div>
                </div>
                <template v-if="isSmallScreen">
                  <div class="data" v-if="centerData">
                    <p>环比昨日评估数量</p>
                    <p class="up" style="color: #E40011;font-weight:bold;"
                      v-if="centerData.today_data.yesterday_ratio>=0"><span
                        style="font-size: 24px;">{{centerData.today_data.yesterday_ratio}}</span>%</p>
                    <p class="down" style="color: #73BD36;font-weight:bold;"
                      v-if="centerData.today_data.yesterday_ratio<0"><span
                        style="font-size: 24px;">{{centerData.today_data.yesterday_ratio}}</span>%</p>
                    <p>日均评估数量</p>
                    <p style="color: #E40011;margin-top: 20px;font-weight:bold;"><span
                        style="font-size: 24px;">{{centerData.today_data.day_average}}</span></p>
                  </div>
                </template>
                <template v-else>
                  <div class="data" v-if="centerData">
                    <p class="up" style="color: #E40011;font-weight:bold;"
                      v-if="centerData.today_data.yesterday_ratio>=0"><span
                        style="font-size: 24px;">{{centerData.today_data.yesterday_ratio}}</span>%</p>
                    <p class="down" style="color: #73BD36;font-weight:bold;"
                      v-if="centerData.today_data.yesterday_ratio<0"><span
                        style="font-size: 24px;">{{centerData.today_data.yesterday_ratio}}</span>%</p>
                    <p>环比昨日评估数量</p>
                    <p style="color: #E40011;margin-top: 20px;font-weight:bold;"><span
                        style="font-size: 24px;">{{centerData.today_data.day_average}}</span></p>
                    <p>日均评估数量</p>
                  </div>
                </template>
              </div>
            </div>
            <div class="pie">
              <p class="part-title">托育机构自评等级分析</p>
              <div id="c2">
                <div class="chart-wrap" style="width: 150px;height:150px;">
                  <div id="chart4">
                  </div>
                  <div class="ratio_text" v-if="centerData">
                    <h2 style="font-size: 24px;text-align:center;">{{selectValue1 || 0}}<span
                        style="font-size: 14px;">%</span></h2>
                    <p style="font-size: 12px;">{{selectText1}}</p>
                  </div>
                </div>
                <div class="color" v-if="centerData">
                  <div style="color:#0079FE">超过国家标准<span>{{centerData.results_arr.results_4}}</span></div>
                  <div style="color:#1ABE6B">符合国家标准<span>{{centerData.results_arr.results_3}}</span></div>
                  <div style="color:#FFBA01">接近国家标准<span>{{centerData.results_arr.results_2}}</span></div>
                  <div style="color:#F04134">需要重大改进<span>{{centerData.results_arr.results_1}}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="map-chart">
            <p style="margin: 30px 0 0;"></p>
            <div id="map"></div>
          </div>
        </div>
        <div class="right">

          <div class="ul">
            <p class="part-title">7大指标自评概况</p>
            <div id="r2">
              <p class="title"><span>一级指标</span><span>分值</span><span>平均得分</span><span>平均用时</span><span>平均得分比重</span></p>
              <p v-for="item of rightData1.object_list" :key="item.id">
                <span>{{item.name}}</span>
                <span>{{item.score*1}}</span>
                <span
                  :style="{color: color[item.average_inspect_score_results]}">{{item.average_inspect_score*1}}</span>
                <span>{{item.average_object_total_date || '00:00:00'}}</span>
                <span :style="{color: color[item.score_ratio_results]}">{{item.score_ratio}}</span>
              </p>
            </div>
          </div>
          <div class="ul">
            <p class="part-title">7大指标自评用时分析</p>
            <div id="r3">
              <div class="chart-wrap">
                <div id="chart5">
                </div>
                <div class="ratio_text" v-if="centerData && !isSmallScreen">
                  <h2 style="font-size: 18px;text-align:center;">{{selectText2}}</h2>
                  <p style="font-size: 12px;">平均用时 {{selectText3}}</p>
                  <p style="font-size: 12px;">占比 {{selectValue2}}%</p>
                </div>
                <div class="ratio_text" v-if="centerData && isSmallScreen">
                  <h2 style="font-size: 16px;text-align:center;">{{selectText2}}</h2>
                  <p style="font-size: 10px;">平均用时 {{selectText3}}</p>
                  <p style="font-size: 10px;">占比 {{selectValue2}}%</p>
                </div>
              </div>
              <template v-if="!isSmallScreen">
                <div class="data-list" v-if="rightData1">
                  <div class="item" v-for="(item,index) of rightData1.object_list.slice(0,5)" :key="item.id">
                    <h2 style="font-size: 14px;" :style="{color: color2[index]}">{{item.name}}</h2>
                    <p style="font-size: 11px;">平均用时 {{item.average_object_total_date || '00:00:00'}}</p>
                    <p style="font-size: 11px;">占比 {{item.total_time_ratio}}%</p>
                  </div>
                </div>
                <div class="data-list" style="float: left;width: 200px;" v-if="rightData1">
                  <div class="item" style="margin-top: -10px;margin-left: 70px;margin-bottom: 20px;"
                    v-for="(item,index) of rightData1.object_list.slice(5,7)" :key="item.id">
                    <h2 style="font-size: 14px;" :style="{color: color2[index+5]}">{{item.name}}</h2>
                    <p style="font-size: 11px;">平均用时 {{item.average_object_total_date || '00:00:00'}}</p>
                    <p style="font-size: 11px;">占比 {{item.total_time_ratio}}%</p>
                  </div>
                </div>
              </template>
              <template v-if="isSmallScreen">
                <div class="data-list" v-if="rightData1">
                  <div class="item" v-for="(item,index) of rightData1.object_list.slice(0,5)" :key="item.id">
                    <h2 style="font-size: 14px;" :style="{color: color2[index]}">{{item.name}}</h2>
                    <p style="font-size: 11px;">平均用时 {{item.average_object_total_date || '00:00:00'}}</p>
                    <p style="font-size: 11px;">占比 {{item.total_time_ratio}}%</p>
                  </div>
                </div>
                <div class="data-list" style="float: left;width: 140px;" v-if="rightData1">
                  <div class="item" style="margin-top: 10px;margin-left: 30px;margin-bottom: 20px;"
                    v-for="(item,index) of rightData1.object_list.slice(5,7)" :key="item.id">
                    <h2 style="font-size: 14px;" :style="{color: color2[index+5]}">{{item.name}}</h2>
                    <p style="font-size: 11px;">平均用时 {{item.average_object_total_date || '00:00:00'}}</p>
                    <p style="font-size: 11px;">占比 {{item.total_time_ratio}}%</p>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="ul">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="7大指标自评分值比重分析" name="1">
                <div id="r4"></div>
              </el-tab-pane>
              <el-tab-pane label="自评不合格问题排行榜" name="2">
                <div id="r5">
                  <div class="list" v-if="rightData2">
                    <el-popover v-for="(item,index) of rightData2.object_list" :key="index" placement="bottom"
                      width="300" trigger="hover" :content="item.name">
                      <div slot="reference" class="item">
                        <span :style="{color: index<3?'#0083F5':'#AEAEAE'}" class="num"
                          v-if="index<9">0{{index+1}}</span>
                        <span :style="{color: index<3?'#0083F5':'#AEAEAE'}" class="num" v-else>{{index+1}}</span>
                        <span class="text ellipsis">{{item.name}}</span>
                      </div>
                    </el-popover>

                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import china from '@/assets/china_2.js'
let proviceMap = {}
china.features.map(e => {
  proviceMap[e.properties.adcode] = e.properties.name;
})
export default {
  name: "charts",
  data() {
    let today = new Date();
    let weekAgo = new Date(today.getTime() - (3600 * 24 * 6 * 1000));
    return {
      activeName: '1',
      r1tab: '1',
      datetime: [weekAgo, today],
      l1: '',
      l2: '',
      c1: '',
      c2: '',
      r1: '',
      r2: '',
      map: '',
      centerData: '',
      rightData1: '',
      leftData1: '',
      leftData2: '',
      rightData2: '',
      city: '',
      color: ['#0079FE', '#1ABE6B', '#FFBA01'],
      isSmallScreen: '',
      time: '',
      timer: '',
      selectValue1: 0,
      selectText1: '',
      selectValue2: 0,
      selectText2: '',
      selectText3: '',
      color2: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452'],
      leftTablePage: 1,
      leftTableData: [],
      finished: false,
      loadmoreLoading: false
    };
  },
  async mounted() {
    this.isSmallScreen = document.body.clientWidth < 1900
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        let x = window.screenWidth < 1900
        this.isSmallScreen = window.screenWidth < 1900
        if (x != this.isSmallScreen) {
          this.isSmallScreen = window.screenWidth < 1900

        }
        this.l1.resize();
        this.l2.resize();
        this.c1.resize();
        this.c2.resize();
        this.r1.resize();
        this.r2.resize();
        console.log(this.isSmallScreen)
      })()
    }

    await this.init();
    await this.initRight1();
    await this.initRight2();
    await this.initLeft1();
    await this.initLeft2();
    await this.leftTable();

    this.$nextTick(() => {
      this.mapChart();
      this.centerChart1();
      this.centerChart2();
      this.leftChart1();
      // this.leftChart2();
      this.rightChart1();
      this.rightChart2();
    })

    this.$refs['l3'].addEventListener('scroll', this.loadmore)

    this.timer = setInterval(() => {
      this.displayTime();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.$refs['l3'].removeEventListener('scroll', this.loadmore)
  },
  methods: {
    async loadmore() {
      let box = document.getElementById('l3');
      let content = this.$refs['realTime'];
      var exp_height = box.clientHeight;
      var clientheight = content.clientHeight;
      var scrollTop = box.scrollTop;
      console.log(clientheight, scrollTop, exp_height)
      if (this.loadmoreLoading) {
        return;
      }
      if (this.finished) {
        return;
      }
      if (exp_height + scrollTop > clientheight - 10) {
        console.log('出现了')
        this.loadmoreLoading = true;
        this.leftTablePage += 1;
        let res = await this.$api({
          method: "get",
          url: "/api/inspect/data/real/time",
          data: {
            page: this.leftTablePage,
            limit: 10
          }
        });
        if (res.code == 0) {
          if (res.data.real_time.length < 10) {
            this.finished = true;
          }
          this.leftTableData = this.leftTableData.concat(res.data.real_time);
        }
        this.loadmoreLoading = false;
      } else {
        this.loadmoreLoading = false;
        // console.log('no出现了')
      }
    },
    async leftTable() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/real/time",
        data: {
          page: this.leftTablePage,
          limit: 10
        }
      });
      if (res.code == 0) {
        this.leftTableData = res.data.real_time;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    checkTime(val) {
      if (val < 10) {
        return "0" + val
      } else {
        return val
      }
    },
    displayTime() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      let h = date.getHours();
      let min = date.getMinutes();
      let s = date.getSeconds();
      this.time = y + '-' + this.checkTime(m) + '-' + this.checkTime(d) + ' ' + this.checkTime(h) + ':' + this.checkTime(min) + ':' + this.checkTime(s)
    },
    async init() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/index",
      });
      if (res.code == 0) {
        this.centerData = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async initRight2() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/unqualified",
      });
      if (res.code == 0) {
        this.rightData2 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    getDateFormat(time) {
      let d = new Date(time);
      return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    },
    async initLeft1() {
      console.log(this.datetime);
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/trend/analysis",
        data: {
          type: this.r1tab,
          start_date: this.getDateFormat(this.datetime[0]),
          end_date: this.getDateFormat(this.datetime[1])
        }
      });
      if (res.code == 0) {
        this.leftData1 = res.data;
        this.leftChart1();
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async initLeft2() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/province/analysis",
      });
      if (res.code == 0) {
        this.leftData2 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async initRight1() {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/object/analysis",
      });
      if (res.code == 0) {
        this.rightData1 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    handleClick() {

    },
    async mapChart() {
      let data1 = [];
      let pd = this.centerData.province_data;
      for (const iterator of this.centerData.province_data) {
        iterator.name = proviceMap[iterator.code]
      }
      data1 = this.centerData.province_data.map(e => {
        return {
          name: e.name,
          value: e.org_count * 1
        }
      })
      this.map = this.$echarts.init(document.getElementById('map'));
      this.$echarts.registerMap('map', china, {});
      let option = {
        tooltip: {
          trigger: 'item',
          confine: true,
          showDelay: 0,
          renderMode: 'html',
          transitionDuration: 0.2,
          className: 'echarts-tooltip',
          formatter: function (params, ticket, callback) {
            if (pd[params.dataIndex]) {
              return (`<p style="font-weight: bold;line-height: 2">${params.name}</p><p>托育机构数量：${pd[params.dataIndex].org_count}</p><p>已自评托育机构数量：${pd[params.dataIndex].inspect_count}</p><p>自评完成率：${pd[params.dataIndex].inspect_ratio}</p>`);
            } else {
              return (`<p style="font-weight: bold;line-height: 2">${params.name}</p>`);
            }
          },
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: 'rgba(50,50,50,0)',
          textStyle: {
            color: '#fff',
          }
        },
        visualMap: {
          show: false,
          left: 'right',
          min: 0,
          max: 50,
          inRange: {
            color: [
              '#fff',
              '#eef8ff',
              '#cce7fe',
              '#80c1fc',
              '#62a2ed',
            ]
          },
          calculable: true
        },
        series: [{
          name: '',
          type: 'map',
          zoom: 1.2,
          roam: false,
          scaleLimit: {
            min: 1.2,
            max: 1.2
          },
          label: {
            show: true,
            position: 'insideBottomRight',
            offset: [0, 0],
            fontSize: 11,
            verticalAlign: 'bottom'
          },
          map: 'map',
          emphasis: {
            label: {
              show: true
            }
          },
          data: data1
        }],
      };
      this.map.setOption(option);
    },
    centerChart1() {
      this.c1 = this.$echarts.init(document.getElementById('chart3'));
      var option = {
        series: [{
          type: 'liquidFill',
          data: [this.centerData.top_data.inspect_ratio_all.replace('%', '') * 1 / 100],
          radius: '100px',
          outline: {
            borderDistance: '3px',
            itemStyle: {
              borderColor: '#59b2f8',
              borderWidth: 6,
              shadowBlur: ''
            }
          },
          backgroundStyle: {
            color: '#fff',
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 6,
              shadowBlur: '',
              opacity: 0
            }
          },
          itemStyle: {
            color: '#b2d8fa',
            insideColor: '#b2d8fa',
            opacity: 1,
            shadowColor: '#fff',
          },
          label: {
            show: false,
            fontSize: 30,
            color: '#0083F5',
            insideColor: '#0083F5'
          }
        }]
      };
      option && this.c1.setOption(option);
    },
    centerChart2() {
      this.c2 = this.$echarts.init(document.getElementById('chart4'));
      let arr = this.centerData.results_arr;
      let data = [{
        value: arr.results_4,
        name: '超过国家标准'
      },
      {
        value: arr.results_3,
        name: '符合国家标准'
      },
      {
        value: arr.results_2,
        name: '接近国家标准'
      },
      {
        value: arr.results_1,
        name: '需要重大改进'
      }
      ];
      let t = arr.results_1 + arr.results_2 + arr.results_3 + arr.results_4;
      let max = Math.max(arr.results_1, arr.results_2, arr.results_3, arr.results_4)
      let index = [arr.results_4, arr.results_3, arr.results_2, arr.results_1].indexOf(max);
      this.selectValue1 = max ? ((max / t) * 10000 / 100).toFixed(1) : 0
      this.selectText1 = data[index].name
      let option = {
        color: ['#0079FE', '#1ABE6B', '#FFBA01', '#F04134'],
        series: [{
          type: 'pie',
          radius: ['65%', '80%'],
          selectedMode: 'single',
          avoidLabelOverlap: false,
          cursor: 'pointer',
          label: {
            show: false,
            position: 'center'
          },
          selectedOffset: 2,
          select: {
            label: {
              show: false,
            },
            itemStyle: {
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          emphasis: {
            label: {
              show: false,
            }
          },
          labelLine: {
            show: false
          },
          colorBy: 'data',
          data: data
        }]
      };;
      option && this.c2.setOption(option);
      this.c2.on('selectchanged', (params) => {
        console.log(params)
        let max = data[params.selected[0].dataIndex].value;
        let t = arr.results_1 + arr.results_2 + arr.results_3 + arr.results_4;
        this.selectValue1 = max ? ((max / t) * 10000 / 100).toFixed(1) : 0
        this.selectText1 = data[params.selected[0].dataIndex].name
      })
    },
    rightChart1() {
      this.r1 = this.$echarts.init(document.getElementById('chart5'));
      let arr = this.rightData1.object_list;
      for (const iterator of arr) {
        iterator.value = iterator.total_time_ratio * 1;
      }
      console.log(arr[0])
      this.selectValue2 = arr[0].total_time_ratio;
      this.selectText2 = arr[0].name;
      this.selectText3 = arr[0].average_object_total_date || '00:00:00';
      let color = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452'];
      let option = {
        color: color,
        series: [{
          name: '占比',
          startAngle: 45,
          selectedMode: 'single',
          avoidLabelOverlap: false,
          cursor: 'pointer',
          type: 'pie',
          radius: ['50%', '60%'],
          avoidLabelOverlap: false,
          emphasis: {
            label: {}
          },
          selectedOffset: 2,
          select: {
            itemStyle: {
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            show: false,
            edgeDistance: 10,
            alignTo: 'edge',
            minMargin: 4,
            formatter: function (params) {
              return `{name|${arr[params.dataIndex].name}}\n{time|平均用时${arr[params.dataIndex].average_object_total_date || '00:00:00'}}`
            },
            rich: {
              name: {
                fontSize: 12,
                fontWeight: 'bold',
                lineHeight: 20,
              },
              time: {
                fontSize: 11,
                color: '#999',
                lineHeight: 15,
              }
            }
          },
          labelLine: {
            show: false,
            length: 30,
            length2: 30,
            maxSurfaceAngle: 180,
            minTurnAngle: 0
          },
          data: arr,
        }]
      };
      option && this.r1.setOption(option);
      this.r1.on('selectchanged', (params) => {
        console.log(params)
        let dataIndex = [params.selected[0].dataIndex];
        this.selectValue2 = arr[dataIndex].total_time_ratio;
        this.selectText2 = arr[dataIndex].name;
        this.selectText3 = arr[dataIndex].average_object_total_date || '00:00:00';
      })
    },
    rightChart2() {
      this.r2 = this.$echarts.init(document.getElementById('r4'));
      let arr = this.rightData1.object_list;
      for (const iterator of arr) {
        iterator.value = iterator.total_time_ratio * 1;
      }
      let option = {
        grid: {
          left: 45,
          bottom: 50,
          top: 50,
          right: 20
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          },
          showDelay: 0,
          renderMode: 'html',
          transitionDuration: 0.2,
          className: 'echarts-tooltip',
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: 'rgba(50,50,50,0)',
          textStyle: {
            color: '#fff',
          }
        },
        legend: {
          data: ['得分比重', '分值比重']
        },
        xAxis: [{
          type: 'category',
          data: arr.map(e => {
            return '' + e.name.slice(0, 2) + '\n' + (e.name.slice(2, 4)) + ''
          }),
          nameTextStyle: {
            width: 4
          },
          axisPointer: {
            type: 'shadow'
          }
        }],
        yAxis: [{
          type: 'value',
          name: '',
          min: 0,
          max: 25,
          interval: 5,
          axisLabel: {
            formatter: '{value}%'
          }
        }],
        series: [{
          left: 10,
          name: '得分比重',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value + '%';
            }
          },
          data: arr.map(e => {
            return e.inspect_score_ratio.replace('%', '')
          })
        },
        {
          left: 10,
          name: '分值比重',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value + '%';
            }
          },
          data: arr.map(e => {
            return e.object_score_ratio.replace('%', '')
          })
        }
        ]
      };
      option && this.r2.setOption(option);
    },
    leftChart1() {
      console.log()
      let list = this.leftData1.data;
      this.l1 = this.$echarts.init(document.getElementById('chart1'));
      let option = {
        grid: {
          left: 30,
          bottom: 66,
          top: 30
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          formatter: '{b}<br/>{a}：{c}',
          showDelay: 0,
          renderMode: 'html',
          transitionDuration: 0.2,
          className: 'echarts-tooltip',
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: 'rgba(50,50,50,0)',
          textStyle: {
            color: '#fff',
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: list.map(e => {
            return e.date.slice(5, 10) + (this.r1tab == 3 ? '月' : '')
          })
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          splitNumber: 5,
          max: function (value) {
            return value.max + 6;
          }
        },
        series: [{
          name: (this.r1tab == 3 ? '本月评估的机构' : (this.r1tab == 2 ? '本周评估的机构' : '今日评估的机构')),
          data: list.map(e => {
            return e.inspect_num
          }),
          type: 'line',
          areaStyle: {}
        }]
      };
      option && this.l1.setOption(option);
    },
    leftChart2() {
      let list = this.leftData2.province_list;
      for (const iterator of list) {
        iterator.name = proviceMap[iterator.code]
        iterator.value = iterator.total;
      }
      console.log(list)
      this.l2 = this.$echarts.init(document.getElementById('chart2'));
      let option = {
        series: [{
          selectedOffset: 2,
          select: {
            itemStyle: {
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          type: 'pie',
          startAngle: 45,
          radius: [30, 70],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          roseType: 'area',
          emphasis: {
            itemStyle: {
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          itemStyle: {
            borderRadius: 4
          },
          label: {
            edgeDistance: 10,
            alignTo: 'edge',
            minMargin: 4,
            formatter: function (params) {
              return `{name|${list[params.dataIndex].name}}\n{time|机构${list[params.dataIndex].value}所} {time|占比${list[params.dataIndex].org_ratio}}`
            },
            rich: {
              name: {
                fontSize: 12,
                fontWeight: 'bold',
                lineHeight: 20,
              },
              time: {
                fontSize: 11,
                color: '#999',
                lineHeight: 15,
              }
            }
          },
          data: list.map(e => {
            return {
              name: e.name,
              value: e.total
            }
          })
        }]
      };
      option && this.l2.setOption(option);
    }
  },

  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .charts {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: calc(100vh - 150px);
    min-width: 1124px;

    .main {
      margin-bottom: 50px;
      width: 100%;

      .header {
        width: 100%;
        position: relative;

        .time {
          position: absolute;
          font-size: 16px;
          font-weight: 400;
          color: #0083F5;
          right: 40px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .main-title {
        width: 379px;
        height: 46px;
        border: 3px solid #0083F5;
        font-size: 24px;
        font-weight: bold;
        color: #0083F5;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .content {
        display: flex;
        justify-content: space-around;
      }

      .left,
      .right {
        flex: 0 0 290px;
        width: 290px;
      }

      .center {
        flex: auto;
        margin: 56px 10px;
      }

      .center {
        flex: auto;
        // width: 550px;
      }

      .part-title {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
        margin: 15px 0;
      }

      .left {
        #l1 {
          height: 235px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;

          .top {
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;
            height: 40px;
          }

          .picker {
            width: 100px;
          }

          .chart {
            height: 230px;
            width: 90%;
            margin: 0 auto;
          }
        }

        #l2 {
          // height: 462px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;

          .chart {
            height: 238px;
            width: 90%;
            margin: 0 auto;
          }

          .list {
            height: 200px;
            padding: 0 25px 20px;

            .title,
            p {
              display: flex;
              justify-content: space-between;
              font-size: 12px;

              span {
                text-align: center;
                margin-bottom: 8px;
              }

              span:nth-of-type(1) {
                flex: 0 1 70px;
                font-weight: bold;
              }

              span:nth-of-type(2) {
                flex: 0 1 60px;
              }

              span:nth-of-type(3) {
                flex: 0 1 70px;
              }

              span:nth-of-type(4) {
                flex: 0 1 70px;
              }

              span:nth-of-type(5) {
                flex: 1;
              }
            }

            .title {
              font-size: 12px;
              color: #666;

              span:nth-of-type(1) {
                font-weight: normal;
              }
            }
          }
        }

        #l3 {
          height: 420px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 6px;
          overflow-y: auto;

          .real-time {
            padding: 5px 23px;

            .item {
              padding: 10px 0 10px;
              border-bottom: 1px solid #e7e7e7;
              display: flex;
              flex-direction: column;
              justify-content: center;

              p {
                display: flex;
                justify-content: space-between;
              }

              p:first-of-type {
                margin-top: 5px;
                line-height: 20px;
                position: relative;
                margin-bottom: 10px;

                span:first-of-type {
                  font-size: 14px;
                  font-weight: bold;
                  display: inline-block;
                  width: 150px;
                }

                span:last-of-type {
                  font-size: 14px;
                }
              }

              p:last-of-type {
                line-height: 20px;
                color: #666;

                span:first-of-type {
                  font-size: 12px;
                  width: 150px;
                }

                span:last-of-type {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      #r1 {
        height: 180px;
        border-radius: 6px;
        background: #fff;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .l,
        .r {
          width: 50%;
          display: flex;
          // padding-left: 20px;
          font-size: 14px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          img {
            flex: 0 0 39px;
            margin-bottom: 10px;
            height: 38px;
          }

          p {
            text-align: center;
          }
        }
      }

      .right {


        #r2 {
          height: 220px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;
          padding: 17px 6px;

          .title,
          p {
            display: flex;
            justify-content: space-between;
            font-size: 12px;

            span {
              text-align: center;
              margin-bottom: 8px;
            }

            span:nth-of-type(1) {
              flex: 0 1 55px;
              font-weight: bold;
            }

            span:nth-of-type(2) {
              flex: 0 1 40px;
            }

            span:nth-of-type(3) {
              flex: 0 1 55px;
            }

            span:nth-of-type(4) {
              flex: 0 1 55px;
            }

            span:nth-of-type(5) {
              flex: 1;
            }
          }

          .title {
            font-size: 12px;
            color: #666;

            span:nth-of-type(1) {
              font-weight: normal;
            }
          }
        }

        #r3 {
          height: 324px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;

          .data-list {
            width: 120px;
            float: right;
            text-align: left;

            .item {
              margin-top: 8px;
            }
          }

          .chart-wrap {
            width: 170px;
            height: 170px;
            position: relative;
            float: left;

            .ratio_text {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);

              p,
              h2 {
                text-align: center;
              }
            }
          }

          #chart5 {
            width: 100%;
            height: 100%;
          }
        }

        #r4 {
          height: 272px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;
          padding-top: 20px;
        }

        #r5 {
          height: 272px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;

          .list {
            height: 100%;
            overflow-y: auto;

            .item {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 0 27px;

              &:first-of-type {
                margin-top: 10px;
              }

              .num {
                width: 26px;
                height: 14px;
                background: #ECF6FF;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                margin-right: 12px;
                display: block;
                color: #0083F5;
              }

              .text {
                color: #000;
                font-size: 12px;
                flex: 0 0 220px;
                text-align: left;
              }
            }
          }
        }
      }

      .main-chart {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;

        .pie {
          width: calc((100% - 20px) / 2);
        }

        #c1 {
          width: 100%;
          height: 259px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: column;
          padding: 0 40px;

          .chart-wrap {
            position: relative;
            margin-right: 30px;
            margin-top: 30px !important;
            margin-bottom: 10px !important;
          }

          .data {
            font-size: 14px;
            margin-top: 13px;
            height: 140px;
            width: 270px;

            .up::after {
              content: '';
              display: inline-block;
              width: 14px;
              height: 15px;
              background: #000;
              background: url('../assets/charts/up.png') no-repeat center;
              background-size: 100%;
              margin-left: 4px;
            }

            .down::after {
              content: '';
              display: inline-block;
              width: 14px;
              height: 15px;
              background: #000;
              background: url('../assets/charts/down.png') no-repeat center;
              background-size: 100%;
              margin-left: 4px;
            }

            p {
              float: left;
              margin-top: 0 !important;
              line-height: 30px;
              margin-top: 20px;
              padding: 0 5px;
            }

            p:nth-of-type(2n + 1) {
              text-align: right;
              width: 55%;

            }

            p:nth-of-type(2n) {
              text-align: left;
              width: 45%;
            }
          }
        }

        #chart3 {
          width: 120px;
          height: 120px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          transform: translateY(-15px);
          margin-right: 30px;
          position: relative;

          &::after {
            content: '今日托育机构评估数量', ;
            font-size: 14px;
            word-break: break-all;
            white-space: nowrap;
            margin-top: 120px;
          }
        }

        .chart-wrap {
          position: relative;
          margin-right: 30px;
        }

        .ratio_text {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;

          h2,
          p {
            margin: 0 auto;
            text-align: center;
            line-height: 20px;
          }
        }

        #c2 {
          width: 100%;
          height: 259px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: column;
          padding: 0 40px;

          #chart4 {
            width: 150px;
            height: 150px;

            .ratio {
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }

          .color {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            >div {
              display: flex;
              font-size: 14px;
              line-height: 26px;

              span {
                margin-left: 12px;
                color: #000;
                display: inline-block;
                width: 40px;
              }
            }
          }
        }
      }

      .number {
        display: flex;
        justify-content: space-around;

        .item {
          flex: 1;
          height: 96px;
          margin: 0 10px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-left: 38px;
          background: url('../assets/charts/b1.png') no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;

          p {
            font-size: 14px;
            color: #FFFFFF;
            margin: 0;
          }

          p:first-of-type {
            color: #fff;
            font-size: 32px;
            font-weight: bold;
            line-height: 1;
          }
        }

        .item:nth-of-type(2) {
          background: url('../assets/charts/b2.png') no-repeat;
          background-size: 100% 100%;
        }

        .item:nth-of-type(3) {
          background: url('../assets/charts/b3.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .map-chart {
      height: 450px;

      #map {
        height: 100%;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (min-width: 1900px) {
  .charts {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: calc(100vh - 150px);
    min-width: 1124px;

    .main {
      margin-bottom: 50px;
      width: 100%;

      .header {
        width: 100%;
        position: relative;

        .time {
          position: absolute;
          font-size: 16px;
          font-weight: 400;
          color: #0083F5;
          right: 40px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .main-title {
        width: 379px;
        height: 46px;
        border: 3px solid #0083F5;
        font-size: 24px;
        font-weight: bold;
        color: #0083F5;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .content {
        display: flex;
        justify-content: space-between;
      }

      .left,
      .right {
        flex: 0 0 384px;
        width: 384px;
      }

      .center {
        flex: auto;
        margin: 56px 25px;
      }

      .center {
        flex: 0 0 787px;
      }

      .part-title {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
        margin: 15px 0;
      }

      .left {
        #l1 {
          height: 235px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;

          .top {
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;
            height: 40px;
          }

          .picker {
            width: 200px;
          }

          .chart {
            height: 230px;
            width: 90%;
            margin: 0 auto;
          }
        }

        #l2 {
          // height: 462px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;

          .chart {
            height: 238px;
            width: 90%;
            margin: 0 auto;
          }

          .list {
            height: 200px;
            padding: 0 25px 20px;

            .title,
            p {
              display: flex;
              justify-content: space-between;
              font-size: 12px;

              span {
                text-align: center;
                margin-bottom: 8px;
              }

              span:nth-of-type(1) {
                flex: 0 1 70px;
                font-weight: bold;
              }

              span:nth-of-type(2) {
                flex: 0 1 60px;
              }

              span:nth-of-type(3) {
                flex: 0 1 70px;
              }

              span:nth-of-type(4) {
                flex: 0 1 70px;
              }

              span:nth-of-type(5) {
                flex: 1;
              }
            }

            .title {
              font-size: 12px;
              color: #666;

              span:nth-of-type(1) {
                font-weight: normal;
              }
            }
          }
        }

        #l3 {
          height: 420px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 6px;
          overflow-y: auto;

          .real-time {
            padding: 5px 23px;

            .item {
              padding: 10px 0 10px;
              border-bottom: 1px solid #e7e7e7;
              display: flex;
              flex-direction: column;
              justify-content: center;

              p {
                display: flex;
                justify-content: space-between;
              }

              p:first-of-type {
                margin-top: 5px;
                line-height: 20px;
                position: relative;
                margin-bottom: 10px;

                span:first-of-type {
                  font-size: 14px;
                  font-weight: bold;
                  display: inline-block;
                  width: 150px;
                }

                span:last-of-type {
                  font-size: 14px;
                }
              }

              p:last-of-type {
                line-height: 20px;
                color: #666;

                span:first-of-type {
                  font-size: 12px;
                  width: 150px;
                }

                span:last-of-type {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      #r1 {
        height: 138px;
        border-radius: 6px;
        background: #fff;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .l,
        .r {
          width: 50%;
          display: flex;
          padding-left: 20px;
          font-size: 14px;
          display: flex;
          align-items: center;

          img {
            flex: 0 0 39px;
            margin-right: 10px;
            height: 38px;
          }
        }
      }

      .right {
        #r2 {
          height: 220px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;
          padding: 17px 20px;

          .title,
          p {
            display: flex;
            justify-content: space-between;
            font-size: 12px;

            span {
              text-align: center;
              margin-bottom: 8px;
            }

            span:nth-of-type(1) {
              flex: 0 1 70px;
              font-weight: bold;
            }

            span:nth-of-type(2) {
              flex: 0 1 60px;
            }

            span:nth-of-type(3) {
              flex: 0 1 70px;
            }

            span:nth-of-type(4) {
              flex: 0 1 70px;
            }

            span:nth-of-type(5) {
              flex: 1;
            }
          }

          .title {
            font-size: 12px;
            color: #666;

            span:nth-of-type(1) {
              font-weight: normal;
            }
          }
        }

        #r3 {
          height: 344px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;

          .data-list {
            width: 150px;
            float: right;
            text-align: left;

            .item {
              margin-top: 12px;
            }
          }

          .chart-wrap {
            width: 220px;
            height: 220px;
            position: relative;
            float: left;

            .ratio_text {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);

              p,
              h2 {
                text-align: center;
              }
            }
          }

          #chart5 {
            width: 100%;
            height: 100%;
          }
        }

        #r4 {
          height: 272px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;
          padding-top: 20px;
        }

        #r5 {
          height: 272px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;
          cursor: pointer;

          .list {
            height: 100%;
            overflow-y: auto;

            .item {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 0 27px;

              &:first-of-type {
                margin-top: 10px;
              }

              .num {
                width: 26px;
                height: 14px;
                background: #ECF6FF;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                margin-right: 12px;
                display: block;
                color: #0083F5;
              }

              .text {
                color: #000;
                font-size: 12px;
                flex: 0 0 290px;
                text-align: left;
              }
            }
          }
        }
      }

      .main-chart {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        #c1 {
          width: 380px;
          height: 167px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 0 40px;

          .data {
            font-size: 14px;
            margin-top: 13px;
            height: 140px;

            .up::after {
              content: '';
              display: inline-block;
              width: 14px;
              height: 15px;
              background: #000;
              background: url('../assets/charts/up.png') no-repeat center;
              background-size: 100%;
              margin-left: 4px;
            }

            .down::after {
              content: '';
              display: inline-block;
              width: 14px;
              height: 15px;
              background: #000;
              background: url('../assets/charts/down.png') no-repeat center;
              background-size: 100%;
              margin-left: 4px;
            }
          }
        }

        #chart3 {
          width: 120px;
          height: 120px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          transform: translateY(-15px);
          margin-right: 30px;
          position: relative;

          &::after {
            content: '今日托育机构评估数量', ;
            font-size: 14px;
            word-break: break-all;
            white-space: nowrap;
            margin-top: 120px;
          }
        }

        .chart-wrap {
          position: relative;
          margin-right: 30px;
        }

        .ratio_text {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;

          h2,
          p {
            margin: 0 auto;
            text-align: center;
            line-height: 20px;
          }
        }

        #c2 {
          width: 380px;
          height: 167px;
          border-radius: 6px;
          background: #fff;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 0 40px;

          #chart4 {
            width: 150px;
            height: 150px;

            .ratio {
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }

          .color {
            >div {
              display: flex;
              font-size: 14px;
              line-height: 30px;

              span {
                margin-left: 12px;
                color: #000;
              }
            }
          }
        }
      }

      .number {
        display: flex;
        justify-content: space-between;

        .item {
          flex: 0 0 246px;
          height: 88px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-left: 35px;
          background: url('../assets/charts/b1.png') no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;

          p {
            font-size: 14px;
            color: #FFFFFF;
            margin: 0;
          }

          p:first-of-type {
            color: #fff;
            font-size: 32px;
            font-weight: bold;
            line-height: 1;
          }
        }

        .item:nth-of-type(2) {
          background: url('../assets/charts/b2.png') no-repeat;
          background-size: 100% 100%;
        }

        .item:nth-of-type(3) {
          background: url('../assets/charts/b3.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .map-chart {
      height: 650px;

      #map {
        height: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
.el-range-editor--mini .el-range__close-icon {
  display: none;
}

.echarts-tooltip {
  text-align: left;
}
</style>
